import { NavLink } from '@remix-run/react'
import type { NavLinkProps } from '@remix-run/react'

import { Trans } from 'react-i18next'
import { cva } from 'cva'
import { useContext } from 'react'
import classNames from 'clsx'

import { NavigationMenuContext } from './NavigationMenuContext'

interface LinkModel {
  path: string
  label: string
}

const NavigationMenuItem: React.FCC<{
  link: LinkModel
  disabled?: boolean
  className?: string
  end?: NavLinkProps['end']
  prefetch?: NavLinkProps['prefetch']
}> = ({ link, end, disabled, prefetch, ...props }) => {
  const label = link.label

  const menuProps = useContext(NavigationMenuContext)

  return (
    <NavLink
      prefetch={prefetch ?? 'intent'}
      end={end}
      className={({ isActive }) => {
        const className = getNavigationMenuItemClassBuilder()({
          active: isActive,
          ...menuProps,
        })

        return classNames(className, props.className ?? ``)
      }}
      aria-disabled={disabled}
      to={disabled ? '' : link.path}
    >
      <Trans i18nKey={label} defaults={label} />
    </NavLink>
  )
}

export default NavigationMenuItem

function getNavigationMenuItemClassBuilder() {
  return cva(
    [
      `colors flex items-center justify-center rounded-md p-1 text-sm font-medium transition active:translate-y-[2px] lg:justify-start lg:px-2.5`,
    ],
    {
      compoundVariants: [
        // not active - shared
        {
          active: false,
          className: `text-gray-600 hover:text-gray-900 active:text-current
        dark:text-gray-200 dark:hover:text-white`,
        },
        // active - shared
        {
          active: true,
          className: `text-gray-900 dark:text-white`,
        },
        // active - pill
        {
          active: true,
          pill: true,
          className: `dark:bg-primary-300/10 bg-gray-50 text-gray-600`,
        },
        // not active - pill
        {
          active: false,
          pill: true,
          className: `dark:hover:bg-background dark:active:bg-dark-900/90 text-gray-500 hover:bg-gray-50 active:bg-gray-100 dark:text-gray-300`,
        },
        // not active - bordered
        {
          active: false,
          bordered: true,
          className: `dark:hover:bg-dark/90 dark:active:bg-dark-800 rounded-lg border-transparent transition-colors hover:bg-gray-50 active:bg-gray-100`,
        },
        // active - bordered
        {
          active: true,
          bordered: true,
          className: `border-primary text-primary-700 top-[0.4rem] rounded-none border-b-[0.25rem] bg-transparent pb-[0.8rem] dark:text-white`,
        },
        // active - secondary
        {
          active: true,
          secondary: true,
          className: `bg-transparent font-semibold`,
        },
      ],
      variants: {
        active: {
          true: ``,
        },
        pill: {
          true: `py-2`,
        },
        bordered: {
          true: `relative h-10`,
        },
        secondary: {
          true: ``,
        },
      },
    },
  )
}
